import Swiper from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';

const specialistSliderClass = '.js-specialists-slider';

const sliderSpcialists = new Swiper(specialistSliderClass, {
    modules: [Autoplay, Navigation],
    loop: false,
    speed: 650,
    spaceBetween: 80,
    navigation: {
        nextEl: '.specialists-slider--next',
        prevEl: '.specialists-slider--prev',
    },
    autoplay: {
        delay: 1500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
    },
    breakpoints: {
        500: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        991: {
            slidesPerView: 3,
            spaceBetween: 40,
        },
        1200: {
            slidesPerView: 4,
        },
    },
});
